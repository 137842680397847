import axios from "axios";
import { useEffect, useRef, useState } from "react";
import RegconLogo from "../../assets/regcon.png";
import SyringeImage from "../../assets/syringes.png";
import { useParams } from 'react-router-dom';

import "./ResetPassword.css";

const API_URL = process.env?.REACT_APP_API_URL || "";

function ResetPasswordPage() {
    const { passwordResetKey } = useParams();

    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!#%*?&-])[A-Za-z0-9@$!#%*?&-]{6,}$/;


    const sendResetRequest = (e) => {
        e.preventDefault()
        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            alert('Passwords do not match')
            return
        }
        if (!passwordRegex.test(passwordRef.current.value)) {
            alert("Password must include\n1 lowercase character\n1 uppercase character\n1 number\n6 characters");
            return
        }

        axios.post(`${API_URL}/reset-password`, {
            resetKey: passwordResetKey,
            newPassword: passwordRef.current.value
        })
    }

    return (
        <div className='login-main-container'>
            <div className='login-display'>
                <div className='login-display-header'>
                    <a className='main-link' href={"/"}>
                        <img src={RegconLogo} alt='' />
                    </a>
                </div>
                <form onSubmit={sendResetRequest} className='login-box'>
                    <div className='login-box-title'>
                        <h1>Welcome to Regcon Software.</h1>
                        <p>Sign up and get access to powerful software.</p>
                    </div>
                    <div
                        className='split-login-box-container'
                        id='split-container'
                    >
                        <div className='split-login-box' id='basic-info-box'>
                            <label>New Password *</label>
                            <input
                                placeholder='Password'
                                className='primary-input login-input'
                                ref={passwordRef}
                            />
                            <label>Confirm Password *</label>
                            <input
                                placeholder='Confirm Password'
                                className='primary-input login-input'
                                ref={confirmPasswordRef}
                            />
                            <button
                                onClick={sendResetRequest}
                                className='primary-button login-input'
                            >
                                Reset Password
                            </button>
                        </div>
                    </div>
                    <p>
                        Already have an account? <a href={"/login"}>Login!</a>
                    </p>
                </form>
            </div>
            <div className='login-decorator-panel'>
                <img src={SyringeImage}  alt='' />
            </div>
        </div>
    );
}

export default ResetPasswordPage;
