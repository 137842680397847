import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './index.css';
import RegconLogo from '../../assets/regcon.png';
import SearchIcon from '../../assets/search.svg';

import contactIcon from '../../assets/contact.svg';
import curiousIcon from '../../assets/curious.svg';
import securityIcon from '../../assets/security.svg';
import authKeyIcon from '../../assets/auth-key.svg';
import interactIcon from '../../assets/interact.svg';
import addCircleIcon from '../../assets/add-circle.svg';

const API_URL = process.env?.REACT_APP_API_URL || '';

const HelpPage = () => {
    const [shouldDisplayAdmin, setShouldDisplayAdmin] = useState(false);
    const [searchResults, setSearchResults] = useState(null);
    const [userTickets, setUserTickets] = useState([]);
    const [displayCreateTicket, setDisplayCreateTicket] = useState(false);
    const [newTicketId, setNewTicketId] = useState(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [displayAllTickets, setDisplayAllTickets] = useState(false)
    const [displayContactMenu, setDisplayContactMenu] = useState(false)

    const searchRef = useRef();
    const ticketSubjectRef = useRef();
    const ticketContentRef = useRef();

    const userNameRef = useRef();
    const userEmailRef = useRef();
    const userPhoneRef = useRef();
    const userSubjectRef = useRef();
    const userMessageRef = useRef();
    const submitDetails = () => {
        if (
            !userNameRef.current.value ||
            !userEmailRef.current.value ||
            !userPhoneRef.current.value ||
            !userSubjectRef.current.value ||
            !userMessageRef.current.value
        ) {
            alert('Please fill in all the fields');
        } else {
            axios
                .post(`${API_URL}/contactus`, {
                    name: userNameRef.current.value,
                    email: userEmailRef.current.value,
                    phone: userPhoneRef.current.value,
                    subject: userSubjectRef.current.value,
                    message: userMessageRef.current.value,
                })
                .then(() => {
                    alert('Your message has been sent successfully');
                })
                .catch(() => {
                    alert('Your message was not successful');
                });
        }
    };

    function getCookie(cookieName) {
        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
            const [name, value] = cookies[i].split('=');
            if (name === cookieName) {
                return decodeURIComponent(value);
            }
        }
        return null;
    }

    const checkIsAdmin = () => {
        axios.get(`${API_URL}/admin/display`).then((resp) => {
            if (resp.data.isAdmin) {
                setShouldDisplayAdmin(true);
            }
        });
    };

    useEffect(() => {
        setIsLoggedIn(!!getCookie('Authentication'))
        checkIsAdmin();
        getUserTickets();
    }, []);

    const getUserTickets = () => {
        axios.get(`${API_URL}/api/tickets`).then((resp) => {
            setUserTickets(resp.data.tickets);
        });
    };

    const searchArticles = () => {
        axios
            .get(`${API_URL}/api/articles/search`, {
                params: { query: searchRef.current.value },
            })
            .then((resp) => {
                setSearchResults(resp.data.articles);
            });
    };

    const createUserTicket = () => {
        if (!ticketSubjectRef.current.value.trim().length || !ticketContentRef.current.value.trim().length) {
            alert('Cannot have empty ticket');
            return;
        }
        axios
            .post(`${API_URL}/api/tickets`, {
                subject: ticketSubjectRef.current.value,
                content: ticketContentRef.current.value,
            })
            .then((resp) => {
                setNewTicketId(resp.data.ticketId);
                getUserTickets()
            });
    };

    const toggleFaq = (faqIndex) => {
        let currentDisplayType = document.getElementById(`faq${faqIndex}`).style.display;
        document.getElementById(`faq${faqIndex}`).style.display = currentDisplayType === 'none' ? 'initial' : 'none';
    };

    const getLines = (e) => {
        if (!ticketContentRef.current) return;
        const textarea = ticketContentRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleKeyPress = (e) => {
        if (e.key !== 'Enter') {
            return;
        }
        searchArticles();
    };

    return (
        <div className='help-desk-container'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon' />
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>

                    {shouldDisplayAdmin && (
                        <a className='header-link login-link' href='/admin'>
                            Admin
                        </a>
                    )}
                    {isLoggedIn ? (
                        <div className='header-link-container'>
                            <a className='header-link button-link-light' href='/home'>
                                Home
                            </a>
                            <a className='header-link login-link' href='/logout'>
                                Logout
                            </a>
                        </div>
                    ) : (
                        <>
                            <div className='header-link-container'>
                                <a className='header-link button-link-light' href='/login'>
                                    Log In
                                </a>
                            </div>
                            <div className='header-link-container'>
                                <a className='header-link button-link' href='/signup'>
                                    Sign Up
                                </a>
                            </div>
                        </>
                    )}

                </div>
            </div>

            <div className='help-search-container'>
                <h1>Here to help!</h1>
                <div className='help-search-bar'>
                    <input
                        ref={searchRef}
                        type='text'
                        placeholder='What issue are you having?'
                        onKeyDown={handleKeyPress}
                    />
                    <button onClick={searchArticles}>
                        <img src={SearchIcon} alt='Search' />
                    </button>
                </div>
                <p>Search for an article or create a ticket</p>
                <div class='wave'></div>
                <div class='wave'></div>
                <div class='wave'></div>
            </div>

            <div className='search-results'>
                {searchResults === null ? null : (
                    <>
                        <p className='search-results-title'>
                            {searchResults.length ? 'Search results:' : 'No results found'}
                        </p>
                        {searchResults.map((result) => (
                            <a href={`/help/articles/${result.url}/`} className='search-result'>
                                <p className='search-result-title'>{result.title}</p>
                                <p className='search-result-section'>{result.category}</p>
                                <p className='search-result-preview'>{result.content}</p>
                            </a>
                        ))}
                        {!!searchResults && isLoggedIn && (
                            <div onClick={() => setDisplayCreateTicket(true)} className='search-result'>
                                <p className='search-result-title'>Create a ticket</p>
                                <p className='search-result-section'>Ticket</p>
                                <p className='search-result-preview'>Need more help? Make a ticket.</p>
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className='sections'>
                <div className='section-questions-answers'>
                    <h3>Common Asked</h3>
                    <div className='common-faq-container'>
                        <div onClick={() => toggleFaq(0)}>
                            <div className='common-faq'>
                                <img src={addCircleIcon} alt='+' />
                                Why does my key say it is invalid?
                            </div>
                            <div id='faq0' className='common-faq-answer' style={{ display: 'none' }}>
                                <p>Make sure it is not in use and that your key is correct.</p>
                                <a href='/help/articles/authentication/why-is-my-key-not-working'>Read more</a>
                            </div>
                        </div>

                        <div onClick={() => toggleFaq(1)}>
                            <div className='common-faq'>
                                <img src={addCircleIcon} alt='+' />
                                How to get a copy?
                            </div>
                            <div id='faq1' className='common-faq-answer' style={{ display: 'none' }}>
                                <p>
                                    Signing up and agreeing to submit feedback during the beta period grants you a key.
                                </p>
                                <a href='/help/articles/faq/how-do-i-get-a-key'>Read more</a>
                            </div>
                        </div>

                        <div onClick={() => toggleFaq(2)}>
                            <div className='common-faq'>
                                <img src={addCircleIcon} alt='+' />
                                How is my data stored?
                            </div>
                            <div id='faq2' className='common-faq-answer' style={{ display: 'none' }}>
                                <p>Limited information about your account is stored on our secure database.</p>
                                <a href='/help/articles/security/how-is-my-data-stored'>Read more</a>
                            </div>
                        </div>

                        <div onClick={() => toggleFaq(3)}>
                            <div className='common-faq'>
                                <img src={addCircleIcon} alt='+' />
                                What are comments?
                            </div>
                            <div id='faq3' className='common-faq-answer' style={{ display: 'none' }}>
                                <p>Comments are a way to mark points of your sequences and make notes of them. </p>
                                <a href='/help/articles/viewer/what-are-comments'>Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-tickets'>
                    {isLoggedIn ? (<>
                        <h3>Your Tickets</h3>
                        <div className='tickets'>
                            {userTickets
                                .slice(0, 3)
                                .map((ticket) => (
                                    <a href={`/help/ticket/${ticket.id}`} className='ticket-box'>
                                        <p>{ticket.title}</p>
                                        <div className={`notification ${ticket.status === 'new' && ' alert'}`}></div>
                                    </a>
                                ))}

                            {userTickets.length > 3 && <div onClick={() => setDisplayAllTickets(true)} className='ticket-box link'>
                                All other tickets
                            </div>}
                            
                        </div>
                                {!userTickets.length && <h3 className='login-ticket-message'>No active support tickets</h3>}
                        <button className='create-button' onClick={() => setDisplayCreateTicket(true)}>
                            Create Ticket
                        </button>
                    </>) : (
                        <div>
                            <h3 className='login-ticket-message'>Log in to see your support tickets</h3>
                        </div>
                    )}
                </div>
            </div>

            {displayAllTickets && (
                <>
                    <div onClick={() => setDisplayAllTickets(false)} className='modal-back'></div>
                    <div className='ticket-modal tickets-modal-container'>
                        <h1>All Tickets</h1>

                        <div className='ticket-model-content tickets-modal'>
                            {userTickets

                                .map((ticket) => (
                                    <a href={`/help/ticket/${ticket.id}`} className='ticket-box'>
                                        <p>{ticket.title}</p>
                                        <div className={`notification ${ticket.status === 'new' && ' alert'}`}></div>
                                    </a>
                                ))}
                        </div>

                    </div>
                </>
            )}

            {displayCreateTicket && (
                <>
                    <div onClick={() => setDisplayCreateTicket(false)} className='modal-back'></div>
                    <div className='ticket-modal'>
                        <h1>Create Ticket</h1>
                        {newTicketId ? (
                            <div className='ticket-model-content'>
                                <h2>Your ticket has been created!</h2>
                                <a className='ticket-link' href={`/help/ticket/${newTicketId}`}>Go to ticket</a>
                            </div>
                        ) : (
                            <div className='ticket-model-content'>
                                <p>What is the ticket about?</p>
                                <input ref={ticketSubjectRef} placeholder='Subject' className='ticket-subject-input' />
                                <p>How can we help?</p>
                                <textarea
                                    onInput={getLines}
                                    rows={4}
                                    ref={ticketContentRef}
                                    placeholder='Your message'
                                    className='ticket-subject-textarea'
                                ></textarea>
                                <button onClick={createUserTicket} className='create-button'>
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

            {displayContactMenu && (
                <>
                    <div onClick={() => setDisplayContactMenu(false)} className='modal-back'></div>
                    <div className='ticket-modal tickets-modal-container'>
                        <h1>Contact Us</h1>

                        <div className='ticket-model-content contact-info'>
                            <p className='contact-info-title'>Email:</p>
                            <p className='contact-info-text'>joshua.defesche@gmail.com</p>
                            <p className='contact-info-title'>Phone:</p>
                            <p className='contact-info-text'>(760) 704-7489</p>
                            <div className='contact-form-box'>
                                <div className='contact-row-box'>
                                    <div className='contact-input-box'>
                                        <label>Your Name</label>
                                        <br />
                                        <input ref={userNameRef} name='name' type='text' placeholder='Your Name Here' />
                                    </div>
                                    <div className='contact-input-box'>
                                        <label>Your Email</label>
                                        <br />
                                        <input ref={userEmailRef} name='email' type='text' placeholder='Your Email Here' />
                                    </div>
                                </div>

                                <div className='contact-row-box'>
                                    <div className='contact-input-box'>
                                        <label>Your Phone</label>
                                        <br />
                                        <input
                                            ref={userPhoneRef}
                                            name='phone'
                                            type='text'
                                            placeholder='Your Phone Number Here'
                                        />
                                    </div>
                                    <div className='contact-input-box'>
                                        <label>Subject</label>
                                        <br />
                                        <input ref={userSubjectRef} name='subject' type='text' placeholder='Enter Subject' />
                                    </div>
                                </div>

                                <div className='contact-row-box'>
                                    <div className='contact-input-box'>
                                        <label>Message</label>
                                        <br />
                                        <textarea ref={userMessageRef} placeholder='Enter Message Here' rows={5} />
                                    </div>
                                </div>
                            </div>

                            <button className='contact-submit-button' onClick={submitDetails}>
                                Submit
                            </button>
                        </div>

                    </div>
                </>
            )}

            <div className='question-section'>
                <a href='/help/articles/viewer' className='question-genre'>
                    <h4>Using the viewer</h4>
                    <p>What does what?</p>
                    <img src={interactIcon} alt='' />
                </a>
                <a href='/help/articles/authentication' className='question-genre'>
                    <h4>Authentication</h4>
                    <p>Problem logging in?</p>
                    <img src={authKeyIcon} alt='' />
                </a>

                <a href='/help/articles/security' className='question-genre'>
                    <h4>Data and Security</h4>
                    <p>How is your data stored?</p>
                    <img src={securityIcon} alt='' />
                </a>
                <a href='/help/articles/faq' className='question-genre'>
                    <h4>F.A.Q's</h4>
                    <p>Still curious?</p>
                    <img src={curiousIcon} alt='' />
                </a>
                <div className='question-genre contact-us' onClick={() => setDisplayContactMenu(true)}>
                    <h4>Contact Us</h4>
                    <p>Something else?</p>
                    <img src={contactIcon} alt='' />
                </div>
            </div>
            <div className='body-footer'>
                <img className='body-footer-logo' src={RegconLogo} alt='RegCon Logo' />
                <div className='footer-button-row'>
                    <a href='/#features'>Features</a>
                    <a href='/#how-it-works'>How it Works</a>
                    <a href='/#pricing'>Pricing</a>
                    <a href='/#contact-us'>Contact Us</a>
                    <a href='/privacy'>Privacy Policy</a>
                    <a href='/terms'>Terms & Conditions</a>
                </div>
                <p className='footer-text'>Copyright © RegCon Solutions, LLC. All rights reserved.</p>
            </div>
        </div>
    );
};

export default HelpPage;
