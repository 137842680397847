import { useEffect, useState } from "react";
import axios from "axios";
import RegconLogo from "../../assets/regcon.png";
import "./Home.css";
import DownloadIcon from "../../assets/download_icon.svg";
import RequestKeyModal from "../RequestKeyModal";
import SubmitFeedbackModal from "../SubmitFeedbackModal";

const API_URL = process.env?.REACT_APP_API_URL || "";

const HomePage = () => {
    const [activationKeys, setActivationKeys] = useState([]);
    const [isLoadingKeys, setIsLoadingKeys] = useState(true);
    const [deactivatingKeys, setDeactivatingKeys] = useState(new Set());
    const [visibleFileDownloads, setVisibleFileDownloads] = useState([]);
    const [shouldDisplayFeedbackModal, setShouldDisplayFeedbackModal] =
        useState(false);
    const [shouldDisplayAdmin, setShouldDisplayAdmin] = useState(false);

    const productMap = {
        ectd_viewer: "eCTD Viewer",
    };
    const downloadMap = {
        ectd_viewer: "ectd",
    };

    const updateAcativationKey = () => {
        setIsLoadingKeys(true);
        axios.get(`${API_URL}/license`).then((resp) => {
            setIsLoadingKeys(false);
            setActivationKeys(resp.data.licenses);
            let tempVisibleDownloads = new Set(visibleFileDownloads);
            for (let product of resp.data.licenses.map(
                (license) => license.product
            )) {
                if (!tempVisibleDownloads.has(product))
                    tempVisibleDownloads.add(product);
            }
            setVisibleFileDownloads([...tempVisibleDownloads]);
        });
    };

    const handleKeyClick = (e) => {
        try {
            navigator.clipboard.writeText(e.target.value);
        } catch (e) {
            console.error(e);
        }

        e.target.select();
    };

    const deactivateKey = (licenseKey) => {
        axios
            .post(`${API_URL}/license/reset`, {
                license: licenseKey,
            })
            .then((resp) => {
                setActivationKeys([]);
                updateAcativationKey();
            })
            .catch((resp) => {
                alert("Error Deactivating Key");
            });
    };

    const logout = () => {
        axios.post(`${API_URL}/logout`).then((resp) => {
            window.localStorage.removeItem("authToken");
            window.location.assign("/");
        });
    };

    const closeModal = () => {
        setShouldDisplayFeedbackModal(false);
    };

    const getDateDifferenceDays = (dateA, dateB) => {
        const timeDifference = dateA.getTime() - dateB.getTime();
        const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        return dayDifference;
    };

    const checkIsAdmin = () => {
        axios.get(`${API_URL}/admin/display`).then((resp) => {
            if (resp.data.isAdmin) {
                setShouldDisplayAdmin(true);
            }
        });
    };

    useEffect(() => {
        updateAcativationKey();
        checkIsAdmin();
    }, []);

    return (
        <div className='body-container'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon'/>
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>
                    <a className='header-link login-link' href='/home'>
                        Home
                    </a>
                    {shouldDisplayAdmin && (
                        <a className='header-link login-link' href='/admin'>
                            Admin
                        </a>
                    )}
                    <a className='header-link login-link' href='/logout'>
                        Logout
                    </a>
                </div>
            </div>
            <h1>eCTD Viewer Home Page</h1>

            <div>
                <div className='button-list'>
                    <button
                        className='primary-button medium-button'
                        onClick={() => setShouldDisplayFeedbackModal(true)}
                    >
                        Submit Feedback
                    </button>
                </div>
                <SubmitFeedbackModal
                    shouldDisplay={shouldDisplayFeedbackModal}
                    closeModal={closeModal}
                />
            </div>

            <div>
                <h2>License Keys</h2>
                {isLoadingKeys ? (
                    <div>
                        <h2 className=''>Loading</h2>
                    </div>
                ) : null}
                {activationKeys.length ? (
                    <>
                        {activationKeys.map((activationKey) => (
                            <div
                                key={activationKey.license_key}
                                className='license-key-container'
                            >
                                <div className='license-title-row'>
                                    <p className='product-value-title text-bolden text-large'>
                                        {productMap[activationKey.product]} key
                                    </p>

                                    <a
                                        href={`/download/${
                                            downloadMap[activationKey.product]
                                        }`}
                                        download
                                    >
                                        <img src={DownloadIcon}></img>
                                    </a>
                                </div>

                                <p className='product-value-title'>
                                    <span className='text-bolden'>
                                        Expires:
                                    </span>{" "}
                                    {new Date(
                                        activationKey.expiration_date
                                    ).toDateString()}
                                    <span className='date-countdown'>
                                        {getDateDifferenceDays(
                                            new Date(
                                                activationKey.expiration_date
                                            ),
                                            new Date()
                                        ) < 0
                                            ? `Expired`
                                            : `(${getDateDifferenceDays(
                                                  new Date(
                                                      activationKey.expiration_date
                                                  ),
                                                  new Date()
                                              )} days)`}
                                    </span>
                                </p>
                                <div></div>
                                <p className='license-key-title text-bolden'>
                                    License Key:
                                </p>
                                <div className='licence-handler-box'>
                                    <input
                                        onClick={(e) => handleKeyClick(e)}
                                        className='license-key-box'
                                        type='text'
                                        value={activationKey.license_key}
                                        readOnly
                                    />
                                    <button
                                        onClick={() =>
                                            deactivateKey(
                                                activationKey.license_key
                                            )
                                        }
                                        className='deactivate-key-button'
                                        disabled={
                                            !activationKey.activated_machine
                                        }
                                    >
                                        Deactivate Key
                                    </button>
                                </div>
                                {deactivatingKeys.has(
                                    activationKey.license_key
                                ) ? (
                                    <div>
                                        <h2 className=''>Loading</h2>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </>
                ) : (
                    <>{!isLoadingKeys ? <p>No license keys found</p> : null}</>
                )}
            </div>
        </div>
    );
};

export default HomePage;
