import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import axios from 'axios';
import RegconLogo from '../../assets/regcon.png';

import contactIcon from '../../assets/contact.svg';
import curiousIcon from '../../assets/curious.svg';
import securityIcon from '../../assets/security.svg';
import authKeyIcon from '../../assets/auth-key.svg';
import interactIcon from '../../assets/interact.svg';

const API_URL = process.env?.REACT_APP_API_URL || '';

const HelpArticlePage = () => {
    const ARTICLE = window.__ARTICLE__ || {
        "title": "How do I get a key?",
        "url": "how-do-i-get-a-key",
        "content": "Right now keys are free in beta! This means that just by signing up for an account and agreeing to supply feedback during the beta period, you can gain acess to a free copy as long as the beta lasts.",
        "markdown": "# Free Keys During Beta\n\nRight now, we are excited to offer **free keys** during our beta period! By simply signing up for an account and agreeing to provide feedback throughout the beta, you are eligible to receive a **free copy** of our product. This offer is available exclusively during the beta period, making it a fantastic opportunity to explore our features at no cost. \n\n### How to Get Your Free Key\n\n1. **Sign Up:** Register for an account on our platform.\n2. **Agree to Provide Feedback:** As part of the beta program, your insights and feedback are invaluable to us and help improve our services.\n3. **Enjoy Your Access:** Once signed up, you'll have full access to all the features of our product for the duration of the beta period.\n\nTake advantage of this limited-time offer to experience our latest innovations without any charge!"
    };
    const CATEGORY = window.__CATEGORY__ || "F.A.Q's";
    const { category } = useParams();
    const [shouldDisplayAdmin, setShouldDisplayAdmin] = useState(false);

    const checkIsAdmin = () => {
        axios.get(`${API_URL}/admin/display`).then((resp) => {
            if (resp.data.isAdmin) {
                setShouldDisplayAdmin(true);
            }
        });
    };

    useEffect(() => {
        checkIsAdmin();
    }, []);

    return (
        <div className='help-desk-container'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon' />
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>
                    <a className='header-link login-link' href='/home'>
                        Home
                    </a>
                    {shouldDisplayAdmin && (
                        <a className='header-link login-link' href='/admin'>
                            Admin
                        </a>
                    )}
                    <a className='header-link login-link' href='/logout'>
                        Logout
                    </a>
                </div>
            </div>

            <div className='help-article-container'>
                <h1>
                <a href={`/help`}>Help</a> / <a href={`/help/articles/${category}`}>{CATEGORY}</a> / {ARTICLE.title}
                </h1>
                <div className='markdown-container'>
                    <Markdown>{ARTICLE.markdown}</Markdown>
                </div>
            </div>

            <div className='question-section'>
                <a href='/help/articles/viewer' className='question-genre'>
                    <h4>Using the viewer</h4>
                    <p>What does what?</p>
                    <img src={interactIcon} alt='' />
                </a>
                <a href='/help/articles/authentication' className='question-genre'>
                    <h4>Authentication</h4>
                    <p>Problem logging in?</p>
                    <img src={authKeyIcon} alt='' />
                </a>

                <a href='/help/articles/security' className='question-genre'>
                    <h4>Data and Security</h4>
                    <p>How is your data stored?</p>
                    <img src={securityIcon} alt='' />
                </a>
                <a href='/help/articles/faq' className='question-genre'>
                    <h4>F.A.Q's</h4>
                    <p>Still curious?</p>
                    <img src={curiousIcon} alt='' />
                </a>
                <div className='question-genre contact-us'>
                    <h4>Contact Us</h4>
                    <p>Something else?</p>
                    <img src={contactIcon} alt='' />
                </div>
            </div>

            <div className='body-footer'>
                <img className='body-footer-logo' src={RegconLogo} alt='RegCon Logo' />
                <div className='footer-button-row'>
                    <a href='/#features'>Features</a>
                    <a href='/#how-it-works'>How it Works</a>
                    <a href='/#pricing'>Pricing</a>
                    <a href='/#contact-us'>Contact Us</a>
                    <a href='/privacy'>Privacy Policy</a>
                    <a href='/terms'>Terms & Conditions</a>
                </div>
                <p className='footer-text'>Copyright © RegCon Solutions, LLC. All rights reserved.</p>
            </div>
        </div>
    );
};

export default HelpArticlePage;
