import axios from "axios";
import { useRef, useState } from "react";
import "./Login.css";
import RegconLogo from "../../assets/regcon.png";
import BuildingImage from "../../assets/building.png";
import DoctorImage from "../../assets/doctor.png";

const API_URL = process.env?.REACT_APP_API_URL || "";

function App() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [invalidLoginAttempted, setInvalidLoginAttempted] = useState(false)
    const [passwordResetSent, setPasswordResetSent] = useState(false)
    const [passwordResetFailed, setPasswordResetFailed] = useState(false)
    const [isResettingPassword, setIsResettingPassword] = useState(false)

    const toggleIsResettingPassword = () => {
        setInvalidLoginAttempted(false)
        setPasswordResetSent(false)
        setPasswordResetFailed(false)
        setIsResettingPassword(!isResettingPassword)
    }

    const handleLogin = (e) => {
        e.preventDefault()
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        console.log(API_URL);
        axios
            .post(`${API_URL}/login`, { email, password })
            .then((resp) => {
                // localStorage.setItem('authToken', resp.data.authToken);
                window.location.assign("/home");
            })
            .catch((resp) => {
                if (resp.response.status === 401) {
                    setInvalidLoginAttempted(true)
                } else {
                    alert("Error Logging In");
                }
            });
    };

    const requestPasswordReset = (e) => {
        e.preventDefault()
        const email = emailRef.current.value;
        setPasswordResetSent(false)
        setPasswordResetFailed(false)
        setInvalidLoginAttempted(false)
        axios
            .post(`${API_URL}/request-password-reset`, {
                email
            })
            .then(() => {
                setPasswordResetSent(true)
            })
            .catch(() => {
                setPasswordResetFailed(true)
            })
    }

    return (
        <div className='login-main-container'>
            <div className='login-display'>
                <div className='login-display-header'>
                    <a className='main-link' href={"/"}>
                        <img src={RegconLogo} alt={'RegCon'} />
                    </a>
                </div>
                <form onSubmit={isResettingPassword ? requestPasswordReset : handleLogin} className='login-box' autoComplete="on">
                    <div className='login-box-title'>
                        <h1>Welcome to Regcon Software.</h1>
                        <p>
                            Log in and get access to software and license keys.
                        </p>
                    </div>
                    <label htmlFor='username'>Username *</label>
                    <input
                        type='text'
                        id='username'
                        placeholder='Username'
                        name='username'
                        className='primary-input login-input'
                        ref={emailRef}
                        autoComplete='username'
                        required
                    />
                    {!isResettingPassword && <><label htmlFor="password">Password *</label>
                    <input
                        type='password'
                        id='password'
                        placeholder='Password'
                        name="password"
                        autoComplete='current-password'
                        className='primary-input login-input'
                        ref={passwordRef}
                        required
                    />
                    </>}

                    <input
                        className='primary-button login-input'
                        type="submit"
                        value={isResettingPassword ? 'Reset password' : 'Login'}
                    />
                   {isResettingPassword ? 
                   <p>
                        Have an account? <button onClick={toggleIsResettingPassword} className="secondary-button small-button">Try logging in</button>
                    </p> :
                    <>
                    <p>
                        Don't have an account? <a href="/signup">Sign Up!</a>
                    </p>
                    <p>
                        Forgot password? <button onClick={toggleIsResettingPassword} className="secondary-button small-button">Reset Password</button>
                    </p>
                    </>
                   }
                   {passwordResetSent && (
                        <div className="password-reset-message">
                        <p>Password Reset has been sent!</p>
                        <ul>
                            <li>Check your email for a reset link</li>
                            <li>Check your spam folder</li>
                            <li>If you're still having trouble, contact us <a href='/#contact-us'>Here</a>.</li>
                        </ul>
                    </div>
                   )}
                   {passwordResetFailed && (
                    <div className="invalid-login-message">
                        <p>Oops! It looks like your password reset attempt didn't work. Let's try again:</p>
                        <ul>
                            <li>Double-check your username for any typos.</li>
                            
                        </ul>
                    </div>
                   )}
                    {invalidLoginAttempted && (
                        <div className="invalid-login-message">
                            <p>Oops! It looks like your login attempt didn't work. Let's try again:</p>
                            <ul>
                                <li>Double-check your username for any typos.</li>
                                <li>Make sure your password is correct (remember, it's case-sensitive).</li>
                                <li>If you're still having trouble, contact us for a new password.</li>
                            </ul>
                        </div>
                    )}
                </form>

            </div>
            <div className='login-decorator-panel'>
                <img src={DoctorImage} alt='' />
            </div>
        </div>

    );
}

export default App;
