import { useState, useRef } from "react";
import "./SubmitFeedbackModal.css";
import CloseIcon from "../../assets/close_icon.svg";
import axios from "axios";

const API_URL =
    process.env?.REACT_APP_API_URL ||
    "";

const SubmitFeedbackModal = ({ shouldDisplay, closeModal }) => {
    const [doesAgreeToFeedback, setDoesAgreeToFeedback] = useState(true);
    const [displayMessage, setDisplayMessage] = useState(null);
    const softwareUsageRef = useRef();
    const feedbackTypeRef = useRef()

    const requestKey = () => {
        axios
            .post(`${API_URL}/feedback`, {
                userMessage: softwareUsageRef.current.value,
                feedbackType: feedbackTypeRef.current.value
            })
            .then(() => {
                setDisplayMessage(
                    "Feedback submitted, thank you! Youre key expiration will be extended shortly"
                );
            })
            .catch((resp) => {
                setDisplayMessage("Error submitting feedback");
            })
            .finally(() => {
                setTimeout(() => {
                    setDisplayMessage(null);
                }, 10000);
            });
    };

    if (!shouldDisplay) return null;
    return (
        <>
            <div className='modal-background'></div>
            <div className='modal'>
                <div>
                    <img
                        onClick={closeModal}
                        className='close-button'
                        src={CloseIcon}
                    />
                </div>
                <div>
                    <h1 className='modal-title'>Submit Feedback</h1>
                </div>
                <div className='request-question'>
                    <p>What type of feedback is this?</p>
                    <select className="modal-select" ref={feedbackTypeRef}>
                        <option value={"bug"}>Bug</option>
                        <option value={"feature"}>Feature Request</option>
                        <option value={"other"}>Other</option>
                    </select>
                </div>

                <div className='request-question'>
                    <p>What is your feedback?</p>
                    <textarea
                        ref={softwareUsageRef}
                        className='modal-textarea-input'
                    ></textarea>
                </div>

                <p className='feedback-info'>
                    Submit any and all feedback!
                </p>
                <button
                    className='modal-button'
                    disabled={!doesAgreeToFeedback}
                    onClick={requestKey}
                >
                    Submit Feedback
                </button>
                <p className='display-message-text'>{displayMessage}</p>
            </div>
        </>
    );
};

export default SubmitFeedbackModal;
