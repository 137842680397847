import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';
import AdminPage from './components/AdminPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsPage from './components/TermsPage';
import HelpPage from './components/HelpPage';
import TicketsPage from './components/TicketsPage';
import TicketsPageAdmin from './components/TicketsPageAdmin';
import HelpCategoryPage from './components/HelpCategoryPage';
import HelpArticlePage from './components/HelpArticlePage';
import ResetPasswordPage from './components/ResetPasswordPage';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/help' element={<HelpPage />} />
                <Route path='/admin/ticket/:ticket' element={<TicketsPageAdmin />} />
                <Route path='/help/ticket/:ticket' element={<TicketsPage />} />
                <Route path='/help/articles/:category' element={<HelpCategoryPage />} />
                <Route path='/help/articles/:category/:article' element={<HelpArticlePage />} />
                <Route path='/admin' element={<AdminPage />} />
                <Route path='/privacy' element={<PrivacyPolicyPage />} />
                <Route path='/terms' element={<TermsPage />} />
                <Route path='/reset-password/:passwordResetKey' element={<ResetPasswordPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
